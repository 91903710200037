const aphalbe = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

class SpreadsheetWriter {
  row = 0;
  col = 0;
  worksheet = null;
  constructor(worksheet) {
    this.worksheet = worksheet;
  }
  enter() {
    this.row++;
    this.col = 0;
  }
  up() {
    this.row--;
  }
  down() {
    this.row++;
  }
  left() {
    this.col--;
  }
  right(step = 1) {
    this.col += step;
  }
  write(text) {
    this.worksheet.getCell(this.toAddress()).value = text;
  }
  toAddress(col = 0, row = 0) {
    if (this.col + col < 26)
      return `${aphalbe[(this.col + col) % 26]}${this.row + row + 1}`;
    else
      return `${aphalbe[Math.floor((this.col + col) / 26) - 1]}${
        aphalbe[(this.col + col) % 26]
      }${this.row + row + 1}`;
  }
  font(font) {
    const cell = this.worksheet.getCell(this.toAddress());
    cell.font = {
      ...cell.font,
      ...font
    };
  }
  bold() {
    this.font({ bold: true });
  }
  mergeNext(next = 1) {
    this.worksheet.mergeCells(this.toAddress(), this.toAddress(next));
  }
  textCenter() {
    const cell = this.worksheet.getCell(this.toAddress());
    cell.alignment = { ...cell.alignment, horizontal: "center" };
  }
  textRight() {
    const cell = this.worksheet.getCell(this.toAddress());
    cell.alignment = { ...cell.alignment, horizontal: "right" };
  }
  textLeft() {
    const cell = this.worksheet.getCell(this.toAddress());
    cell.alignment = { ...cell.alignment, horizontal: "left" };
  }
  fill(color) {
    const cell = this.worksheet.getCell(this.toAddress());
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: color }
    };
  }
  border(color = "00000000") {
    const cell = this.worksheet.getCell(this.toAddress());
    cell.border = {
      top: { style: "thin", color: { argb: color } },
      left: { style: "thin", color: { argb: color } },
      bottom: { style: "thin", color: { argb: color } },
      right: { style: "thin", color: { argb: color } }
    };
  }
}
export default SpreadsheetWriter;
