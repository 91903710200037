import _ from "lodash";
import { getFullAddress } from "@/utility/address";

function applyColumnFilters(list, columnFilters) {
  const filterKeys = Object.keys(columnFilters);
  for (let i = 0; i < filterKeys.length; i++) {
    const filterKey = filterKeys[i];
    let filterDelegate = columnFilters[filterKey];
    list = list.filter(item => {
      let value = _.get(item, filterKey);
      if (typeof filterDelegate === "function") {
        return filterDelegate(value, item);
      }
      if (typeof filterDelegate === "string") {
        if (!filterDelegate) {
          return true;
        }
        if (!value) {
          return false;
        }
        const filterValue = filterDelegate.toLowerCase();

        return value
          .toString()
          .toLowerCase()
          .includes(filterValue);
      }
      if (!value) {
        return false;
      }
      return true;
    });
  }

  return list;
}

export function applyFilter(list, searchValue, filters, columnFilters = {}) {
  let columnFilterResult = applyColumnFilters([...list], columnFilters);
  if (!searchValue) return columnFilterResult;
  searchValue = searchValue.toLowerCase();
  return columnFilterResult.filter(item => {
    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index];
      let value = null;
      if (typeof filter === "string") {
        value = _.get(item, filter);
      }
      if (typeof filter === "function") {
        value = filter(item);
      }
      if (!value) continue;
      if (
        value
          .toString()
          .toLowerCase()
          .includes(searchValue)
      )
        return true;
    }
    return false;
  });
}

export function groupBy(list, by) {
  const groups = _.groupBy(list, by);
  const groupsWithKey = _.mapValues(groups, (value, key) => ({
    key,
    data: value
  }));
  return _.values(groupsWithKey);
}

export function matchProperty(property, searchValue) {
  searchValue = searchValue.toLowerCase();
  const { propertyName, addresses } = property || {};
  if (propertyName) {
    if (propertyName.toLowerCase().includes(searchValue)) {
      return true;
    }
  }

  if (addresses) {
    const firstAddress = getFullAddress(addresses[0]);
    if (firstAddress.toLowerCase().includes(searchValue)) {
      return true;
    }
  }

  return false;
}

export function matchProject(project, searchValue) {
  searchValue = searchValue.toLowerCase();
  const { title } = project || {};
  if (title) {
    if (title.toLowerCase().includes(searchValue)) {
      return true;
    }
  }

  return false;
}

export function comparationSort(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
