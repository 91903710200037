import { firebase } from "../services/firebase.service";
import _ from "lodash";
import moment from "moment";

const formatDateRefString = (fromDate, toDate) => {
  if (fromDate == undefined || toDate == undefined) return "";
  return (
    (fromDate && typeof fromDate.toDate === "function"
      ? "From: " +
        fromDate
          .toDate()
          .toLocaleString("en-US")
          .split(",")[0]
      : "") +
    (toDate && typeof toDate.toDate === "function"
      ? " To: " +
        toDate
          .toDate()
          .toLocaleString("en-US")
          .split(",")[0]
      : "")
  ).trim();
};

const formatDuration = durationInHours => {
  const hours = durationInHours - (durationInHours % 1);
  const minutes = Math.round((durationInHours - hours) * 60);
  return `${hours || 0}h ${minutes || 0}m`;
};

const toDateCalendar = val => {
  return val && val.seconds ? [val.toDate()] : [];
};

const toDateFirebase = val => {
  if (_.isEmpty(val)) {
    return "";
  }
  return (
    firebase.firestore.Timestamp.fromDate(new Date(val ? val[0] : [])) || ""
  );
};

const toDisplayDateString = val => {
  if (val && (val.seconds || val._seconds)) {
    const date =
      typeof val.toDate === "function"
        ? val.toDate()
        : firebase.firestore.Timestamp.fromMillis(
            (val.seconds || val._seconds) * 1000 +
              (val.nanoseconds || val._nanoseconds) / 1000000
          ).toDate();
    const yearStr = date.getFullYear();
    const monthStr =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    const dateStr =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

    return `${monthStr}/${dateStr}/${yearStr}`;
  }
  return "";
};

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const convertDateUTCToLocalTimeZone = dateTime => {
  const date = new Date(
    dateTime.getTime() - new Date().getTimezoneOffset() * 60000
  )

  return moment(date).format("MM/DD/YYYY");
};

export {
  formatDateRefString,
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
  sleep,
  formatDuration,
  convertDateUTCToLocalTimeZone
};
