import moment from "moment";
export const currencyUSD = value => {
  if ([null, undefined, ""].includes(value)) return "";
  return parseFloat(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
};

export const percent = value => {
  return Math.round(value * 100) / 100 + "%";
};

export const percentToFixed = value => {
  return value.toFixed(2) + "%";
};
export const MMDDYYYY = value => {
  if (!value) return "";
  if (typeof day === "string") {
    return moment(value).format("MM/DD/YYYY");
  }
  return moment(value).format("MM/DD/YYYY");
};
export const formatPhone = phoneNumber => {
  let numberFormat = (phoneNumber || "").slice(2);
  return phoneNumber
    ? "(+1) " +
        numberFormat.substring(0, 3) +
        "-" +
        numberFormat.substring(3, 6) +
        "-" +
        numberFormat.substring(6)
    : "";
};
