const setLayoutTheme = (self, dark) => {
  const globalTheme = dark === true ? "dark" : "light";
  const $html = self.$$("html");
  // const globalTheme = theme;
  $html.removeClass("theme-dark theme-light").addClass("theme-" + globalTheme);
  self.theme = globalTheme;

  // set theme for swimlane columns
  setCustomComponentsTheme(
    self,
    "div.swimlane-column",
    "swimlane-column",
    dark
  );

  // this.setSettingsThemeDark(dark);
  localStorage.setItem("themeDark", dark.toString());
};

const setCustomComponentsTheme = (
  self,
  componentSelector,
  componentCssClass,
  dark
) => {
  const theme = dark === true ? "dark" : "light";
  const component = self.$$(componentSelector);

  component
    .removeClass(`${componentCssClass}-light ${componentCssClass}-dark`)
    .addClass(`${componentCssClass}-${theme}`);
};

const hexToRgba = hex => {
  const rgbHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let theme = localStorage.getItem("themeDark") === "true" ? true : false;
  const rgba = theme
    ? `rgba(${parseInt(rgbHex[1], 16)},${parseInt(rgbHex[2], 16)},${parseInt(
        rgbHex[3],
        16
      )},0.6)`
    : `rgba(${parseInt(rgbHex[1], 16)},${parseInt(rgbHex[2], 16)},${parseInt(
        rgbHex[3],
        16
      )},0.3)`;
  return rgba;
};

export { setLayoutTheme, setCustomComponentsTheme, hexToRgba };
