import moment from "moment";

export const PHOTOS_SECTION_TEMPLATE_CERTIFICATION_OF_COMPLETION = Object.freeze(
  {
    getTemplate(photos) {
      let rows = "";
      photos.forEach(item => {
        rows += `
          <tr>
            <td rowspan="2" style="width:45%; height: 400px; background: #f6f6f6; border-radius: 8px; text-align:center">
            <img src="${
              item.photoUrl
            }" alt="pic" style="width: 100%;height: 100%;object-fit: contain;">
            </td>
            <td style="width:55%; padding-left: 20px; vertical-align: top; font-size: 16px">${item.notes ||
              ""}</td>
          </tr>
          <tr style="height: 53px">
            <td style="width:55%; padding-left: 20px; font-size: 16px; vertical-align: bottom;">
              <p><span style="color: #adadad">Date:</span> ${item.createdAt &&
                moment(item.createdAt.toDate()).format(
                  "MM/DD/YYYY, hh:mm a"
                )}</p>
              <p style="margin: 0"><span style="color: #adadad">Creator:</span> ${
                item.createdBy
              }</p>
            </td>
          </tr>
        `;
      });
      return `
        <table style="width:100%; border-spacing: 0 30px">
          ${rows}
        </table>
      `;
    }
  }
);
